var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8252c423de12b11a6a69faeccfd13e94c8203991"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const NEXT_PUBLIC_SENTRY_PROJECT_ID = process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID;

Sentry.init({
  dsn: SENTRY_DSN || 'https://5a05dddba08347f6b98e89e4ed51620c@o4504510148706304.ingest.sentry.io/4504510153752576',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  tunnel: 'https://gpxfex6h.oxen.ai',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay(),
    new posthog.SentryIntegration(posthog, 'https://oxen-ai.sentry.io/projects/', NEXT_PUBLIC_SENTRY_PROJECT_ID),
  ],
});
